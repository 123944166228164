/**
 * 卡片管理模块
 */
import axios from './http'
// 卡片信息列表
const cardInfoList = (params) => axios.get('/ParkingLotApi/CardManage/GetPageList', { params: params })
// 计费方案-计时-计卡
const timerWay = (params) => axios.get('/ParkingLotApi/TimeCard/TimeCountSelect', { params: params })
// 新增卡片信息
const addCard = (data) => axios.post('/ParkingLotApi/CardManage/Add', data)
// 删除卡片信息
const deleteCard = (data) => axios.post('/ParkingLotApi/CardManage/Delete', data)
// 月租卡续费界面 初始化
const monthCardRenewalsPage = (params) => axios.get('/ParkingLotApi/CardManage/RenewView', { params: params })
// 月租卡续费
const renewMonthcard = (data) => axios.post('/ParkingLotApi/CardManage/Renew', data)
// 储值卡充值界面 初始化
const rechargeInit = (params) => axios.get('/ParkingLotApi/CardManage/RechargeView', { params: params })
// 储值卡充值
const recharge = (data) => axios.post('/ParkingLotApi/CardManage/Recharge', data)
// 修改卡片信息界面 初始化
const updataCardInit = (params) => axios.get('/ParkingLotApi/CardManage/GetDetails', { params: params })
// 修改卡信息
const updateCard = (data) => axios.post('/ParkingLotApi/CardManage/Update', data)
// 过期执行/余额不足下拉列
const getBindOverdueExecutionList = (params) => axios.get('/ParkingLotApi/CardManage/BindOverdueExecution', { params: params })
// 计费方案下拉列 - 除月卡
const getBilProSelectList = (params) => axios.get('/ParkingLotApi/CardManage/BindCostScheme', { params: params })
// 计费方案下拉列 - 月卡
const getMonthBilProSelectList = (params) => axios.get('/ParkingLotApi/CardManage/BindCostMonth', { params: params })
// 新增/编辑卡片提示
const isAddAdnEdit = (data) => axios.post('/ParkingLotApi/CardManage/IsIncluded', data)
// 获取卡片信息
const getCardDetails = (params) => axios.get('/ParkingLotApi/CardManage/GetDetails', { params: params })
// 卡片续费提示
const monthxufeiPoint = (data) => axios.post('/ParkingLotApi/CardManage/RenewTips', data)
// 卡片使用记录接口
const cardUsageRecordList = (params) => axios.get('/ParkingLotApi/CardManage/CardUsageRecordList', { params: params })

// 导入卡片
const importCard = (data) => axios.post('/ParkingLotApi/CardManage/Import', data)

// 上传文件 —— 卡片模块
const uploadFile = (data) => {
  return axios({
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    url: '/ParkingLotApi/CardManage/UploadPic',
    params: {
      isupload: true
    },
    data
  })
}

export default {
  cardInfoList,
  addCard,
  deleteCard,
  timerWay,
  monthCardRenewalsPage,
  renewMonthcard,
  rechargeInit,
  recharge,
  updataCardInit,
  updateCard,
  getBindOverdueExecutionList,
  getBilProSelectList,
  getMonthBilProSelectList,
  isAddAdnEdit,
  getCardDetails,
  monthxufeiPoint,
  cardUsageRecordList,
  importCard,
  uploadFile
}
