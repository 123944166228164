<template>
  <!-- 计时卡 -->
  <div class="box-card">
    <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px" class="demo-ruleForm">
      <div class="title" style="margin-top: 0;">基本信息</div>
      <el-divider></el-divider>
      <el-row :gutter="0">
        <el-col :span="12">
          <el-form-item label="车牌号：" prop="plate_number">
            <el-input v-model="addForm.plate_number" maxlength="8" placeholder="请输入车牌号"
              @input="(e) => (addForm.plate_number = validSpace(e))"></el-input>
          </el-form-item>
          <el-form-item label="计费方案：" prop="cost_scheme_id">
            <el-select popper-class="my-select" v-model="addForm.cost_scheme_id" placeholder="请选择计费方案">
              <el-option v-for="item in bilProSelectList" :key="item._id" :label="item.scheme_name" :value="item._id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="停车场：" prop="parking_id">
            <el-input v-model="addForm.parking_name" :disabled="id != 'new'" v-if="id != 'new'"></el-input>
            <el-select popper-class="my-select" filterable v-model="addForm.parking_id" placeholder="请选择停车场"
              @change="parkingIdChange" v-else>
              <el-option v-for="item in originParkinList" :key="item.parking_id" :label="item.parking_name"
                :value="item.parking_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- <el-form-item label="卡类型：" prop="car_owner_card_type">
              <el-select popper-class="my-select" :disabled="isedit" v-model="queryForm.car_owner_card_type" placeholder="请选择卡类型" @change="selectChange">
                <el-option v-for="item in getOptions('CarOwnerCardTypeNozero')" :key="item.value" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item> -->

      <div class="title">人员信息</div>
      <el-divider></el-divider>
      <el-row :gutter="0">
        <el-col :span="12">
          <el-form-item label="姓名：" >
            <el-input v-model="addForm.car_owner_name" maxlength="8"
              @input="(e) => (addForm.car_owner_name = validSpace(e))" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="性别：">
            <el-select popper-class="my-select" v-model="addForm.sex" placeholder="请选择性别">
              <el-option label="男" :value="0"></el-option>
              <el-option label="女" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="手机号：">
            <el-input v-model="addForm.phone_number" maxlength="11" @input="(e) => (addForm.phone_number = validSpace(e))"
              placeholder="请输入手机号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="身份证：" prop="id_card">
            <el-input v-model="addForm.id_card" maxlength="18" @input="(e) => (addForm.id_card = validSpace(e))"
              placeholder="请输入身份证号"></el-input>
          </el-form-item>
          <el-form-item label="所在地：">
            <el-input maxlength="30" :value="addForm.address" placeholder="请输入所在地"
              @input="(e) => (addForm.address = validSpacing(e))"></el-input>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input v-model="addForm.remarks" maxlength="50" placeholder="请输入备注"
              @input="(e) => (addForm.remarks = validSpacing(e))"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>
    <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm">返 回</lbButton> -->
    <lbButton type="default" fill icon="confirm" class="checkButton" @click="sendTimeCard" v-preventReClick>保 存</lbButton>
  </div>
</template>

<script>
import request from '@/api/carmanager'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  props: ['id'],
  data () {
    return {
      title: '',
      // 计费方案下拉列
      bilProSelectList: [],
      addForm: {
        parking_name: '',
        car_owner_card_type: 1,
        begin_time: new Date(), // 开始时间
        end_time: new Date('2200-01-01'), // 结束时间
        end_cost_scheme_id: '', // 过期执行id， 同cost_scheme_id
        plate_number: '',
        parking_id: '',
        cost_scheme_id: '',
        car_owner_name: '',
        sex: null,
        phone_number: '',
        id_card: '',
        address: '',
        remarks: ''
      },
      addFormRules: {
        plate_number: [
          { required: true, message: '请输入车牌号', trigger: 'blur' },
          {
            pattern: /^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1})$/,
            message: '常规格式：湘A12345',
            trigger: 'blur'
          }
        ],
        parking_id: [
          { required: true, message: '请选择停车场', trigger: 'blur' }
        ],
        cost_scheme_id: [
          { required: true, message: '请选择计费方案', trigger: 'blur' }
        ],
        // car_owner_name: [
        //   { required: true, message: '请输入姓名', trigger: 'blur' }
        // ],
        // phone_number: [
        //   { required: true, message: '请输入手机号', trigger: 'blur' },
        //   {
        //     pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur'
        //   }
        // ],
        id_card: [
          {
            pattern: /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/, message: '请输入正确的身份证号', trigger: 'blur'
          }
        ]
      }
    }
  },
  created () {
    if (this.id === 'new') {
      this.title = '新增计时卡'
    } else {
      this.title = '编辑计时卡'
      this.fnGetTimeCardInfo()
    }
  },
  mounted () {
    this.requestPorkingList()
  },
  computed: {
    ...mapGetters(['getOptions']),
    ...mapState(['parkingList', 'originParkinList']),
    ...mapState('menuList', ['isRoutW'])
  },
  methods: {
    ...mapActions(['requestPorkingList']),
    ...mapMutations(['setQueryStatus']),
    // 获取计时卡信息
    async fnGetTimeCardInfo () {
      const res = await request.updataCardInit({
        card_id: this.id
      })
      console.log(res)
      if (res && res.Code === 200) {
        this.fnGetBilProSelectList(res.Data.parking_id)
        var that = this
        this.$nextTick(() => {
          that.addForm.parking_name = res.Data.parking_name
          that.addForm.car_owner_card_type = res.Data.car_owner_card_type
          that.addForm.begin_time = res.Data.begin_time
          that.addForm.end_time = res.Data.end_time
          that.addForm.end_cost_scheme_id = res.Data.end_cost_scheme_id
          that.addForm.plate_number = res.Data.plate_number
          that.addForm.parking_id = res.Data.parking_id
          that.addForm.cost_scheme_id = res.Data.cost_scheme_id
          that.addForm.car_owner_name = res.Data.car_owner_name
          that.addForm.sex = res.Data.sex === 2 ? null : res.Data.sex
          that.addForm.id_card = res.Data.id_card
          that.addForm.address = res.Data.address
          that.addForm.remarks = res.Data.remarks
          that.addForm.phone_number = res.Data.phone_number
        })
      }
    },
    // goBack () {
    //   if (window.history.length > 1) {
    //     this.setQueryStatus(2)
    //     this.$router.go(-1)
    //   } else {
    //     this.$router.push('/')
    //   }
    // },
    // 停车场选项发生改变时触发
    parkingIdChange (val) {
      this.fnGetBilProSelectList(val)
      this.addForm.cost_scheme_id = ''
    },
    // 停车场绑定计时/计次/时段卡计费方案下拉列
    async fnGetBilProSelectList (id) {
      const res = await request.getBilProSelectList({
        parking_id: id
      })
      if (res && res.Code === 200) {
        this.bilProSelectList = res.Data
      } else {
        this.bilProSelectList = []
      }
    },
    // 保存计时卡
    sendTimeCard () {
      this.$refs.addFormRef.validate((valid) => {
        if (valid) {
          var obj = {
            // 卡片类型 0:临时卡 1:计时卡2:月租卡 3:储值卡 4:贵宾卡
            car_owner_card_type: this.addForm.car_owner_card_type,
            begin_time: this.addForm.begin_time, // 开始时间
            end_time: this.addForm.end_time, // 结束时间
            end_cost_scheme_id: this.addForm.cost_scheme_id, // 过期执行id， 同cost_scheme_id
            plate_number: this.addForm.plate_number,
            parking_id: this.addForm.parking_id,
            cost_scheme_id: this.addForm.cost_scheme_id,
            car_owner_name: this.addForm.car_owner_name,
            sex: this.addForm.sex === 0 || this.addForm.sex === 1 ? this.addForm.sex : 2,
            phone_number: this.addForm.phone_number,
            id_card: this.addForm.id_card,
            address: this.addForm.address,
            remarks: this.addForm.remarks
          }
          this.fnIsAddAdnEdit(obj)
        } else {
          return false
        }
      })
    },
    // 是否可以新增或编辑卡片信息
    async fnIsAddAdnEdit (obj) {
      const res = await request.isAddAdnEdit({
        plate_number: obj.plate_number,
        parking_id: obj.parking_id
      })
      if (res.Code === 500) return
      if (res && res.Code === 200) {
        if (this.title === '新增计时卡') {
          this.fnAddTimeCard(obj)
        } else {
          this.fnUpdateTimeCard(obj)
        }
      } else if (res.Code === 302) {
        this.$confirm(res.Message, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.addForm.begin_time = res.Data.time
          if (this.title === '新增计时卡') {
            this.fnAddTimeCard(obj)
          } else {
            this.fnUpdateTimeCard(obj)
          }
        }).catch(() => {
          if (this.title === '新增计时卡') {
            this.fnAddTimeCard(obj)
          } else {
            this.fnUpdateTimeCard(obj)
          }
        })
      }
    },
    // 新增计时卡请求
    async fnAddTimeCard (obj) {
      const res = await request.addCard(obj)
      console.log(res)
      if (res && res.Code === 200) {
        // this.$router.go(-1)
        this.$emit('closeDialog', false)
      }
    },
    // 编辑计时卡请求
    async fnUpdateTimeCard (obj) {
      obj._id = this.id
      const res = await request.updateCard(obj)
      if (res && res.Code === 200) {
        // this.goBack()
        this.$emit('closeDialog', false)
      }
    },
    defaultForm () {
      this.$emit('closeDialog', false)
    }
  }
}
</script>

<style scoped lang="less">
.title {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  color: #000;
  // margin-top: 50px;
}

.height_div {
  height: 50px;
}

.demo-ruleForm {
  overflow: auto;
  // margin: 10px 0;
  /deep/ .el-input {
    width: 80%;
  }
  /deep/ .el-select {
    width: 100%;
  }
}

.box-card {
  position: relative;
  padding-bottom: 30px;
  margin-top: 0px !important;

  .checkButton {
    position: absolute;
    right: 0px;
  }

  .checkButton2 {
    position: absolute;
    right: 100px;
  }
}</style>
