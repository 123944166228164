<template>
    <!-- 卡片详情 -->
    <div class="box-card">
      <!-- 主要内容 -->
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="卡片信息" name="first">
          <el-form label-width="130px" class="detailForm">
            <el-form-item label="车牌号：">{{cardDetail.plate_number}}</el-form-item>
            <el-form-item label="停车场：">{{cardDetail.parking_name}}</el-form-item>
            <el-form-item label="卡类型：">{{getWayName("CarOwnerCardType", cardDetail.car_owner_card_type)}}</el-form-item>
            <el-form-item label="计费方案：" v-if="cardType != 4">{{cardDetail.cost_scheme_name}}</el-form-item>
            <el-form-item label="开始时间：" v-if="cardType == 2 || cardType == 4">
              {{String(cardDetail.begin_time).startsWith('0001')?'/':$moment(cardDetail.begin_time).format('YYYY-MM-DD')}}
            </el-form-item>
            <el-form-item label="结束时间：" v-if="cardType == 2 || cardType == 4">{{String(cardDetail.end_time).startsWith('0001')?'/':$moment(cardDetail.end_time).format('YYYY-MM-DD')}}</el-form-item>
            <el-form-item label="剩余天数：" v-if="cardType == 2 || cardType == 4">{{$moment(cardDetail.begin_time).diff($moment(), 'day') >= 0 ? $moment(cardDetail.end_time).diff($moment(cardDetail.begin_time), 'day') : $moment(cardDetail.end_time).diff($moment(), 'day') > 0 ? $moment(cardDetail.end_time).diff($moment(), 'day') : 0}}天</el-form-item>
            <!-- <el-form-item label="剩余天数：" v-if="cardType == 2 || cardType == 4">{{$moment(cardDetail.begin_time).diff($moment(), 'day') >= 0 ? $moment(cardDetail.end_time).diff($moment(cardDetail.begin_time), 'day') : $moment(cardDetail.end_time).diff($moment(), 'day')}}天</el-form-item> -->
            <el-form-item label="过期执行：" v-if="cardType == 2 || cardType == 4">{{cardDetail.end_cost_scheme_name}}</el-form-item>
            <el-form-item label="余额不足执行：" v-if="cardType == 3">{{cardDetail.end_cost_scheme_name}}</el-form-item>
            <el-form-item label="余额：" v-if="cardType == 3">￥{{Number(cardDetail.stored_value_amount).toFixed(2)}}</el-form-item>
            <el-form-item label="姓名：">{{cardDetail.car_owner_name}}</el-form-item>
            <el-form-item label="性别：">{{cardDetail.sex === 1 ? '女': cardDetail.sex === 0?'男':''}}</el-form-item>
            <el-form-item label="手机号：">{{cardDetail.phone_number}}</el-form-item>
            <el-form-item label="身份证：" v-if="cardDetail.id_card">{{cardDetail.id_card}}</el-form-item>
            <el-form-item label="所在地：" v-if="cardDetail.address">{{cardDetail.address}}</el-form-item>
            <el-form-item label="备注：" v-if="cardDetail.remarks">
              <span class="remarks_box">{{cardDetail.remarks}}</span>
            </el-form-item>
            <el-form-item label="创建时间："> {{$moment(cardDetail.create_time).format('YYYY-MM-DD HH:mm:ss')}}</el-form-item>
            <el-form-item label="更新时间："> {{$moment(cardDetail.update_time).format('YYYY-MM-DD HH:mm:ss')}}</el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="使用记录" name="second">
          <!-- 表格 -->
          <el-table class="tablestyle" ref="multipleTable" :data="tableData" tooltip-effect="dark" border stripe>
            <el-table-column prop="plate_number" label="车牌号"></el-table-column>
            <el-table-column prop="parking_name" label="停车场"></el-table-column>
            <el-table-column label="类型">
              <template slot-scope="scope">
                <span>{{ getWayName("CarOwnerCardType", scope.row.car_owner_card_type) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="parking_space_name" label="车位编号" v-if="isShow"></el-table-column>
            <el-table-column label="入场时间">
              <template slot-scope="scope"> {{$moment(scope.row.go_in_time).format('YYYY-MM-DD HH:mm:ss')}} </template>
            </el-table-column>
            <el-table-column label="出场时间">
              <!-- <template slot-scope="scope"> {{scope.row.go_out_time}} </template> -->
              <template slot-scope="scope"> {{validDateTime(scope.row.go_out_time)}} </template>
            </el-table-column>
            <el-table-column label="实收金额（元）" v-if="cardType == 1 || cardType == 3">
              <template slot-scope="scope"> {{ Number(scope.row.parking_fee).toFixed(2)}} </template>
            </el-table-column>
            <el-table-column label="剩余天数" v-if="cardType == 2 || cardType == 4">
              <template slot-scope="scope"> {{ $moment(cardDetail.end_time).diff($moment(scope.row.go_out_time), 'day') }} </template>
            </el-table-column>
            <el-table-column label="余额（元）" v-if="cardType == 3">
              <template slot-scope="scope"> {{ Number(scope.row.current_account).toFixed(2) }} </template>
            </el-table-column>
          </el-table>
          <!-- 页码 -->
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize" layout="total, sizes, prev, pager, next, jumper" :total="TotalCount" ></el-pagination>
        </el-tab-pane>
      </el-tabs>

    </div>
  </template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import request from '@/api/carmanager'
export default {
  props: ['id', 'cardType'],
  data () {
    return {
      activeName: 'first',
      // 卡片信息
      cardDetail: {
      },
      // 使用记录查询列表
      queryForm: {
        card_id: '',
        PageIndex: 1,
        PageSize: 10
      },
      // 使用记录
      tableData: [],
      TotalCount: 0,
      // 是否展示车位编号
      isShow: false
    }
  },
  created () {
    console.log(this.id)
    console.log(this.cardType)
    this.fnGetCardDetail()
  },
  mounted () {
  },
  computed: {
    ...mapGetters(['getWayName']),
    ...mapState('menuList', ['isRoutW'])
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    // 获取卡片详情请求
    async fnGetCardDetail () {
      const res = await request.getCardDetails({ card_id: this.id })
      if (res.Code === 200) this.cardDetail = res.Data
    },
    // goBack () {
    //   if (window.history.length > 1) {
    //     this.setQueryStatus(2)
    //     this.$router.go(-1)
    //   } else {
    //     this.$router.push('/')
    //   }
    // },
    // 点击切换
    handleClick (tab, event) {
      if (tab.label === '卡片信息') {
        this.fnGetCardDetail()
      } else if (tab.label === '使用记录') {
        this.fnGetCardUserRecord()
      }
    },
    // 获取卡片使用记录
    async fnGetCardUserRecord () {
      this.queryForm.card_id = this.id
      const res = await request.cardUsageRecordList(this.queryForm)
      console.log(res)
      if (res && res.Code === 200) {
        this.tableData = res.Data.DataList
        this.TotalCount = res.Data.TotalCount
        this.isShow = res.Data.DataList && res.Data.DataList.length > 0 ? res.Data.DataList[0].parking_space_name : ''
      } else {
        this.tableData = []
        this.TotalCount = 0
      }
    },
    // 每页条数改变
    handleSizeChange (size) {
      this.queryForm.PageSize = size
      this.fnGetCardUserRecord()
    },
    // 页码改变
    handleCurrentChange (page) {
      this.queryForm.PageIndex = page
      this.fnGetCardUserRecord()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    defaultForm () {
      this.$emit('closeDialog', false)
    }
  }
}
</script>

  <style scoped lang="less">
  .box-card{
    margin-top: 0px !important;
  }
  .el-tabs {
    /deep/ .el-tabs__content {
      overflow: inherit !important;
    }
    /deep/ .el-tabs__item {
      font-size: 18px !important;
      font-weight: 700 !important;
    }
    /deep/ .el-tabs__nav-wrap::after {
      height: 1.5px;
      background-color: #f2f2f2;
    }
    /deep/ .el-tabs__active-bar {
      height: 1.5px;
    }
    /deep/ .el-tabs__item {
      border-right: none !important;
    }
  }
  .detailForm {
    /deep/ .el-form-item {
      margin-bottom: 0;
      // .el-form-item__label {
      //   line-height: normal;
      //   padding: 10px 0;
      // }
      // .el-form-item__content {
      //   line-height: normal;
      //   padding: 10px 0;
      // }
      .remarks_box {
        word-wrap: break-word;
        padding: 0;
        margin: 0;
      }
    }
  }

  </style>
